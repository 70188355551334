import { Checkbox as MantineCheckbox } from "@mantine/core";
import * as classes from "./Checkbox.css";

export function Checkbox(props: React.ComponentProps<typeof MantineCheckbox>) {
  return (
    <MantineCheckbox
      {...props}
      classNames={{
        input: classes.input,
        icon: classes.icon,
        ...props.classNames,
      }}
    />
  );
}
