import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Text, Title } from "@mantine/core";
import { useForm, UseFormReturnType } from "@mantine/form";
import {
  Link,
  useFetcher,
  useNavigate,
  useOutletContext,
  useParams,
  useRevalidator,
  useRouteLoaderData,
} from "@remix-run/react";
import {
  IconScubaDiving,
  IconRipple,
  IconGauge,
  IconArrowRight,
  IconArrowLeft,
  IconUpload,
  IconPencil,
  IconFish,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { Form } from "~/components/_common/form/Form/Form";
import { DateInput } from "~/components/_common/form/DateInput/DateInput";
import { Modal } from "~/components/_common/Modal/Modal";
import { action as bulkUpdateAction } from "~/routes/_api.bulk-update-dive-logs";
import { action as upsertAction } from "~/routes/_api.update-dive-logs";
import { action as deleteAction } from "~/routes/_api.delete-dive-logs";
import {
  CONSERVATION_ACTIVITIES,
  CORAL_ACTIVITES,
  CURRENT_LABELS,
  DiveLogFormInputs,
  DiveSample,
  MARINE_ACTIVITES,
  OTHER_ACTIVITES,
  SURFACE_CONDITION_LABELS,
  VISIBILITY_LABELS,
} from "~/config/dive-log";
import type {
  DB,
  GalleryPhoto,
  LoggedDive,
  ModalData,
  OutletContext,
  PartialLoggedTrip,
  UserProfile,
} from "~/types/shared";
import { useDiveLogData } from "~/utils/hooks/useDiveLogData";
import { getDiveLogLocation, getDiveSiteName } from "~/utils/getDiveLogHeading";
import { Row } from "~/components/_common/Row/Row";
import { MODALS, ModalTypes } from "~/config/modals";
import { useConvertedValue } from "~/utils/hooks/useConvertedValue";
import { CookieTabs } from "~/components/_common/CookieTabs/CookieTabs";
import { DIVE_LOG_MODAL_TAB } from "~/config/cookie-keys";
import { ImageList } from "~/components/_common/ImageList/ImageList";
import { LineGraph } from "~/components/graphs/LineGraph/LineGraph";
import { UserAvatar } from "~/components/_common/UserAvatar/UserAvatar";
import { PROFILE } from "~/config/routes";
import { COLUMN_IDS } from "~/components/LogBookTable/LogBookTable";
import { Button } from "~/components/_common/Button/Button";
import { TimeInput } from "~/components/_common/form/TimeInput/TimeInput";
import { NumberInput } from "~/components/_common/form/NumberInput/NumberInput";
import {
  distance,
  pressure,
  temperature,
  volume,
} from "~/config/abbreviations";
import { Select } from "~/components/_common/form/Select/Select";
import { EditButton } from "~/components/_common/EditButton/EditButton";
import { getDateOnly } from "~/utils/getDateOnly";
import { Slider } from "~/components/_common/form/Slider/Slider";
import { DiveSiteSearch } from "~/components/DiveSiteSearch/DiveSiteSearch";
import { TripSelect } from "~/components/_common/TripSelect/TripSelect";
import { DiveCompanySearch } from "~/components/DiveCompanySearch/DiveCompanySearch";
import { MultiSelect } from "~/components/_common/form/MultiSelect/MultiSelect";
import { Container } from "~/components/_common/form/Container/Container";
import { showNotication } from "~/config/notifications";
import { Tooltip } from "~/components/_common/Tooltip/Tooltip";
import { IconTitle } from "~/components/_common/IconTitle/IconTitle";
import { COMPANY_TYPES } from "~/config/organisation";

import * as classes from "./DiveLogModal.css";

export const OVERVIEW = "Overview";
export const DIVE_SAMPLES = "Dive samples";
export const GALLERY = "Gallery";
export const DIVE_LOG_MODAL_TABS = [OVERVIEW, DIVE_SAMPLES, GALLERY] as const;
export type DIVE_LOG_MODAL_TABS_TYPES = (typeof DIVE_LOG_MODAL_TABS)[number];
export const FIELDS = {
  dive_site_name: "dive_site_name",
  dive_site_id: "dive_site_id",
  trip_id: "trip_id",
  company_name: "company_name",
  company_id: "company_id",
  conservation_organisation_name: "conservation_organisation_name",
  conservation_organisation_id: "conservation_organisation_id",
  conservation_activities: "conservation_activities",
  entry_date: "entry_date",
  entry_time: "entry_time",
  max_depth: "max_depth",
  dive_duration: "dive_duration",
  dive_type: "dive_type",
  entry_type: "entry_type",
  water_type: "water_type",
  visibility: "visibility",
  current: "current",
  surface_conditions: "surface_conditions",
  min_water_temp: "min_water_temp",
  max_water_temp: "max_water_temp",
  air_temp: "air_temp",
  pressure_end: "pressure_end",
  pressure_start: "pressure_start",
  tank_type: "tank_type",
  tank_size: "tank_size",
  dive_mode: "dive_mode",
  tank_oxygen: "tank_oxygen",
  tank_po2: "tank_po2",
} as const;

const BULK_EDITABLE_FIELDS: (keyof typeof FIELDS)[] = [
  FIELDS.dive_site_name,
  FIELDS.dive_site_id,
  FIELDS.trip_id,
  FIELDS.company_name,
  FIELDS.company_id,
  FIELDS.conservation_organisation_name,
  FIELDS.conservation_organisation_id,
  FIELDS.conservation_activities,
  FIELDS.entry_date,
  FIELDS.dive_type,
  FIELDS.entry_type,
  FIELDS.water_type,
  FIELDS.visibility,
  FIELDS.current,
  FIELDS.surface_conditions,
  FIELDS.min_water_temp,
  FIELDS.max_water_temp,
  FIELDS.air_temp,
  FIELDS.tank_type,
  FIELDS.tank_size,
  FIELDS.dive_mode,
  FIELDS.tank_oxygen,
  FIELDS.tank_po2,
] as const;

const LABEL_MAP = {
  [FIELDS.dive_site_name]: "Dive site",
  [FIELDS.dive_site_id]: "Dive site",
  [FIELDS.trip_id]: "Trip",
  [FIELDS.company_name]: "Centre / Operator / Resort / Liveaboard",
  [FIELDS.company_id]: "Centre / Operator / Resort / Liveaboard",
  [FIELDS.conservation_organisation_name]: "Organisation",
  [FIELDS.conservation_organisation_id]: "Organisation",
  [FIELDS.conservation_activities]: "Activities",
  [FIELDS.entry_date]: "Date",
  [FIELDS.entry_time]: "Entry time",
  [FIELDS.max_depth]: "Max depth",
  [FIELDS.dive_duration]: "Dive duration",
  [FIELDS.dive_type]: "Dive type",
  [FIELDS.entry_type]: "Entry type",
  [FIELDS.water_type]: "Water type",
  [FIELDS.visibility]: "Visibility",
  [FIELDS.current]: "Current",
  [FIELDS.surface_conditions]: "Surface conditions",
  [FIELDS.min_water_temp]: "Min water temp",
  [FIELDS.max_water_temp]: "Max water temp",
  [FIELDS.air_temp]: "Air temp",
  [FIELDS.pressure_end]: "Ending pressure",
  [FIELDS.pressure_start]: "Starting pressure",
  [FIELDS.tank_type]: "Tank type",
  [FIELDS.tank_size]: "Tank size",
  [FIELDS.dive_mode]: "Dive mode",
  [FIELDS.tank_oxygen]: "Oxygen",
  [FIELDS.tank_po2]: "PO2",
} as const;

function SampleGraph({
  loggedDiveSample,
  userProfile,
}: {
  loggedDiveSample?: DiveSample[];
  userProfile: UserProfile;
}) {
  const { sampleData, diveSampleDomains } = useDiveLogData({
    samples: loggedDiveSample,
    preferences: userProfile,
  });

  if (sampleData && !Object.keys(sampleData).length) {
    return (
      <Text m="auto" size="lg">
        No sample data available for this dive log
      </Text>
    );
  }

  return (
    <LineGraph
      data={sampleData}
      domains={diveSampleDomains}
      preferences={userProfile}
    />
  );
}

function TripHeading({ trip }: { trip?: PartialLoggedTrip | null }) {
  const tripLocation = trip?.location;
  const tripStartDate =
    trip?.start_date && dayjs(trip.start_date).format("DD MMM YYYY");
  const tripEndDate =
    trip?.end_date && dayjs(trip.end_date).format("DD MMM YYYY");

  if (!tripLocation) return null;

  return (
    <>
      <div className={classes.tripHeading}>
        {tripLocation}
        <br />
        <span className={classes.tripHeadingDate}>
          {tripStartDate} {tripEndDate ? ` - ${tripEndDate}` : ""}
        </span>
      </div>
    </>
  );
}

function GeneralFields({
  isEditing,
  loggedDive,
  form,
  selectedTrip,
  loggedTrips,
  preferences,
  setCurrentModal,
  isBulkEditing,
}: {
  isEditing: boolean;
  loggedDive?: Partial<LoggedDive> | null;
  form: UseFormReturnType<Partial<DiveLogFormInputs>>;
  selectedTrip?: PartialLoggedTrip | null;
  loggedTrips: PartialLoggedTrip[];
  preferences: DB["user_profiles"]["Row"];
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  isBulkEditing?: boolean;
}) {
  const getConvertedValue = useConvertedValue();
  const { setFieldValue, setValues } = form;

  const handleSetDiveSiteId = useCallback(
    (id: string) => {
      setFieldValue("dive_site_id", id || null);
    },
    [setFieldValue]
  );

  const setDiveCompanyFields = useCallback(
    (
      diveCompanyFields: {
        id: string;
        company_name: string;
      } | null
    ) => {
      const { id, company_name } = diveCompanyFields ?? {};
      setValues({
        company_id: id || null,
        company_name: company_name || null,
      });
    },
    [setValues]
  );

  const {
    entry_date,
    entry_time,
    company_name,
    max_depth,
    dive_duration,
    dive_type,
    entry_type,
    dive_sites,
    temp_dive_site_name,
  } = loggedDive || {};
  const minDate = selectedTrip?.start_date;
  const maxDate = !selectedTrip?.end_date ? minDate : selectedTrip.end_date;

  const isTempDiveSite = !dive_sites && temp_dive_site_name;

  const DiveSiteContent = forwardRef<HTMLAnchorElement>((props, ref) => (
    <Tooltip
      label="Dive site not in our database. Please select one, or create a new one."
      multiline
      w={200}
    >
      <span ref={ref} {...props} className={classes.tempDiveSite}>
        {temp_dive_site_name}
      </span>
    </Tooltip>
  ));

  DiveSiteContent.displayName = "DiveSiteContent";

  let generalData = [
    {
      id: FIELDS.dive_site_id,
      label: LABEL_MAP[FIELDS.dive_site_id],
      value: isTempDiveSite ? (
        <DiveSiteContent />
      ) : (
        dive_sites?.dive_site_name || "-"
      ),
      field: (
        <DiveSiteSearch
          diveSiteIdInputProps={{
            ...form.getInputProps(FIELDS.dive_site_id),
            name: FIELDS.dive_site_id,
          }}
          setDiveSiteId={handleSetDiveSiteId}
          diveSiteNameInputProps={{
            ...form.getInputProps(FIELDS.dive_site_name),
            name: FIELDS.dive_site_name,
          }}
          onChange={(v) => {
            form.setFieldValue(FIELDS.dive_site_name, v);
          }}
          onAddNewDiveSite={() => {
            setCurrentModal(MODALS.DIVE_SITE, {
              dive_site_name: form.values.dive_site_name,
            });
          }}
        />
      ),
    },
    {
      id: FIELDS.trip_id,
      label: LABEL_MAP[FIELDS.trip_id],
      value: selectedTrip ? <TripHeading trip={selectedTrip} /> : "-",
      field: (
        <TripSelect
          trips={loggedTrips}
          selectProps={form.getInputProps(FIELDS.trip_id)}
          form={form}
        />
      ),
    },
    {
      id: FIELDS.company_id,
      label: LABEL_MAP[FIELDS.company_id],
      value: company_name || "-",
      field: (
        <DiveCompanySearch
          hideLabel
          setDiveCompanyFields={setDiveCompanyFields}
          notCompanyType={COMPANY_TYPES.CONSERVATION}
          diveCompanyNameInputProps={{
            ...form.getInputProps(FIELDS.company_name),
            name: FIELDS.company_name,
          }}
          onChange={(v) => {
            form.setFieldValue(FIELDS.company_name, v);
          }}
          onAddNewCompany={() => {
            setCurrentModal(MODALS.CREATE_ORGANISATION, {
              company_name: form.values.company_name,
            });
          }}
        />
      ),
    },
    {
      id: FIELDS.entry_date,
      label: LABEL_MAP[FIELDS.entry_date],
      value: entry_date ? dayjs(entry_date).format("D MMM YYYY") : "-",
      field: (
        <DateInput
          {...form.getInputProps(FIELDS.entry_date)}
          required
          name={FIELDS.entry_date}
          placeholder="Select the date"
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? new Date(maxDate) : undefined}
          defaultDate={minDate ? new Date(minDate) : undefined}
          value={
            minDate && minDate === maxDate
              ? new Date(minDate)
              : form.getInputProps(FIELDS.entry_date).value
          }
          style={{
            minWidth: "50%",
            flex: 1,
          }}
        />
      ),
    },
    {
      id: FIELDS.entry_time,
      label: LABEL_MAP[FIELDS.entry_time],
      value: entry_time
        ? dayjs(`1938-09-16T${entry_time}`).format("HH:mm")
        : "-",
      field: (
        <TimeInput
          required
          name={FIELDS.entry_time}
          {...form.getInputProps(FIELDS.entry_time)}
        />
      ),
    },
    {
      id: FIELDS.dive_duration,
      label: LABEL_MAP[FIELDS.dive_duration],
      value: dive_duration ? `${dive_duration} min` : "-",
      field: (
        <NumberInput
          required
          isInteger
          name={FIELDS.dive_duration}
          rightSection="min"
          {...form.getInputProps(FIELDS.dive_duration)}
          setFieldValue={form.setFieldValue}
          style={{
            minWidth: 125,
          }}
        />
      ),
    },
    {
      id: FIELDS.max_depth,
      label: LABEL_MAP[FIELDS.max_depth],
      value: max_depth ? getConvertedValue(max_depth, "depth") : "-",
      field: (
        <NumberInput
          required
          isInteger
          name={FIELDS.max_depth}
          rightSection={distance[preferences.preferred_depth_unit]}
          setFieldValue={form.setFieldValue}
          {...form.getInputProps(FIELDS.max_depth)}
        />
      ),
    },
    {
      id: FIELDS.dive_type,
      label: LABEL_MAP[FIELDS.dive_type],
      value: dive_type ?? "-",
      field: (
        <Select
          allowDeselect
          data={[
            { value: "recreational", label: "Recreational" },
            { value: "training", label: "Training" },
            { value: "conservation", label: "Conservation" },
          ]}
          name={FIELDS.dive_type}
          {...form.getInputProps(FIELDS.dive_type)}
        />
      ),
    },
    {
      id: FIELDS.entry_type,
      label: LABEL_MAP[FIELDS.entry_type],
      value: entry_type ?? "-",
      field: (
        <Select
          allowDeselect
          data={[
            { value: "boat", label: "Boat" },
            { value: "shore", label: "Shore" },
          ]}
          name={FIELDS.entry_type}
          {...form.getInputProps(FIELDS.entry_type)}
        />
      ),
    },
  ];

  if (isBulkEditing) {
    generalData = generalData.filter(({ id }) =>
      BULK_EDITABLE_FIELDS.includes(id)
    );
  }

  return (
    <div className={classes.tableSection}>
      <Title order={3} className={classes.tableHeading}>
        <IconScubaDiving className={classes.tableHeadingIcon} />
        <span>General</span>
      </Title>
      <table className={classes.dataTable}>
        <tbody>
          {generalData.map(({ label, value, field }) => (
            <tr key={label} className={classes.dataTableRow}>
              <td className={classes.dataLabel}>{label}</td>
              <td className={classes.dataValue}>{isEditing ? field : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function ConditionsFields({
  isEditing,
  loggedDive,
  form,
  preferences,
  isBulkEditing,
}: {
  isEditing: boolean;
  loggedDive?: Partial<LoggedDive> | null;
  form: UseFormReturnType<Partial<DiveLogFormInputs>>;
  preferences: DB["user_profiles"]["Row"];
  isBulkEditing?: boolean;
}) {
  const getConvertedValue = useConvertedValue();

  const {
    water_type,
    visibility,
    current,
    surface_conditions,
    min_water_temp,
    max_water_temp,
    air_temp,
  } = loggedDive || {};

  let conditionsData = [
    {
      id: FIELDS.min_water_temp,
      label: LABEL_MAP[FIELDS.min_water_temp],
      value: getConvertedValue(min_water_temp, "temperature"),
      field: (
        <NumberInput
          setFieldValue={form.setFieldValue}
          name={FIELDS.min_water_temp}
          rightSection={temperature[preferences.preferred_temperature_unit]}
          {...form.getInputProps(FIELDS.min_water_temp)}
        />
      ),
    },
    {
      id: FIELDS.max_water_temp,
      label: LABEL_MAP[FIELDS.max_water_temp],
      value: getConvertedValue(max_water_temp, "temperature"),
      field: (
        <NumberInput
          setFieldValue={form.setFieldValue}
          name={FIELDS.max_water_temp}
          rightSection={temperature[preferences.preferred_temperature_unit]}
          {...form.getInputProps(FIELDS.max_water_temp)}
        />
      ),
    },
    {
      id: FIELDS.air_temp,
      label: LABEL_MAP[FIELDS.air_temp],
      value: getConvertedValue(air_temp, "temperature"),
      field: (
        <NumberInput
          setFieldValue={form.setFieldValue}
          name={FIELDS.air_temp}
          rightSection={temperature[preferences.preferred_temperature_unit]}
          {...form.getInputProps(FIELDS.air_temp)}
        />
      ),
    },
    {
      id: FIELDS.water_type,
      label: LABEL_MAP[FIELDS.water_type],
      value: water_type ?? "-",
      field: (
        <Select
          data={[
            { value: "salt", label: "Salt" },
            { value: "fresh", label: "Fresh" },
          ]}
          name={FIELDS.water_type}
          {...form.getInputProps(FIELDS.water_type)}
        />
      ),
    },
    {
      id: FIELDS.visibility,
      label: LABEL_MAP[FIELDS.visibility],
      value: getConvertedValue(visibility, "visibility"),
      field: (
        <Slider
          name={FIELDS.visibility}
          {...form.getInputProps(FIELDS.visibility)}
          marks={VISIBILITY_LABELS as string[]}
        />
      ),
    },
    {
      id: FIELDS.current,
      label: LABEL_MAP[FIELDS.current],
      value: getConvertedValue(current, "current"),
      field: (
        <Slider
          name={FIELDS.current}
          {...form.getInputProps(FIELDS.current)}
          marks={CURRENT_LABELS as string[]}
        />
      ),
    },
    {
      id: FIELDS.surface_conditions,
      label: LABEL_MAP[FIELDS.surface_conditions],
      value: getConvertedValue(surface_conditions, "surface-conditions"),
      field: (
        <Slider
          name={FIELDS.surface_conditions}
          {...form.getInputProps(FIELDS.surface_conditions)}
          marks={SURFACE_CONDITION_LABELS as string[]}
        />
      ),
    },
  ];

  if (isBulkEditing) {
    conditionsData = conditionsData.filter(({ id }) =>
      BULK_EDITABLE_FIELDS.includes(id)
    );
  }

  return (
    <div className={classes.tableSection}>
      <Title order={3} className={classes.tableHeading}>
        <IconRipple className={classes.tableHeadingIcon} />
        <span>Conditions</span>
      </Title>
      <table className={classes.dataTable}>
        <tbody>
          {conditionsData.map(({ label, value, field }) => (
            <tr key={label} className={classes.dataTableRow}>
              <td className={classes.dataLabel}>{label}</td>
              <td className={classes.dataValue}>{isEditing ? field : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function GasAirFields({
  isEditing,
  loggedDive,
  form,
  preferences,
  isBulkEditing,
}: {
  isEditing: boolean;
  loggedDive?: Partial<LoggedDive> | null;
  form: UseFormReturnType<Partial<DiveLogFormInputs>>;
  preferences: DB["user_profiles"]["Row"];
  isBulkEditing?: boolean;
}) {
  const getConvertedValue = useConvertedValue();

  const {
    pressure_end,
    pressure_start,
    tank_type,
    tank_size,
    dive_mode,
    tank_oxygen,
    tank_po2,
  } = loggedDive || {};

  let gasData: {
    id: keyof typeof FIELDS;
    label: string;
    value: string | number;
    field: JSX.Element;
  }[] = [
    {
      id: FIELDS.pressure_start,
      label: LABEL_MAP[FIELDS.pressure_start],
      value: getConvertedValue(pressure_start, "pressure"),
      field: (
        <NumberInput
          min={0}
          name={FIELDS.pressure_start}
          rightSection={pressure[preferences.preferred_pressure_unit]}
          setFieldValue={form.setFieldValue}
          {...form.getInputProps(FIELDS.pressure_start)}
        />
      ),
    },
    {
      id: FIELDS.pressure_end,
      label: LABEL_MAP[FIELDS.pressure_end],
      value: getConvertedValue(pressure_end, "pressure"),
      field: (
        <NumberInput
          min={0}
          name={FIELDS.pressure_end}
          rightSection={pressure[preferences.preferred_pressure_unit]}
          setFieldValue={form.setFieldValue}
          {...form.getInputProps(FIELDS.pressure_end)}
        />
      ),
    },
    {
      id: FIELDS.tank_type,
      label: LABEL_MAP[FIELDS.tank_type],
      value: tank_type ?? "-",
      field: (
        <Select
          allowDeselect
          data={[
            { value: "steel", label: "Steel" },
            { value: "aluminium", label: "Aluminium" },
            { value: "other", label: "Other" },
          ]}
          name={FIELDS.tank_type}
          {...form.getInputProps(FIELDS.tank_type)}
        />
      ),
    },
    {
      id: FIELDS.tank_size,
      label: LABEL_MAP[FIELDS.tank_size],
      value: getConvertedValue(tank_size, "volume"),
      field: (
        <NumberInput
          min={0}
          name={FIELDS.tank_size}
          rightSection={volume[preferences.preferred_volume_unit]}
          setFieldValue={form.setFieldValue}
          {...form.getInputProps(FIELDS.tank_size)}
        />
      ),
    },
    {
      id: FIELDS.dive_mode,
      label: LABEL_MAP[FIELDS.dive_mode],
      value: dive_mode ?? "-",
      field: (
        <Select
          allowDeselect
          data={[
            { value: "air", label: "Air" },
            { value: "nitrox", label: "Nitrox" },
          ]}
          name={FIELDS.dive_mode}
          {...form.getInputProps(FIELDS.dive_mode)}
        />
      ),
    },
  ];

  if (form.values.dive_mode === "nitrox") {
    gasData = [
      ...gasData,
      {
        id: FIELDS.tank_oxygen,
        label: LABEL_MAP[FIELDS.tank_oxygen],
        value: tank_oxygen ?? "-",
        field: (
          <NumberInput
            setFieldValue={form.setFieldValue}
            {...form.getInputProps(FIELDS.tank_oxygen)}
            min={0}
            name={FIELDS.tank_oxygen}
            rightSection="%"
            disabled={form.values.dive_mode !== "nitrox"}
            value={(form.values.dive_mode !== "nitrox"
              ? ""
              : form.values.tank_oxygen
            )?.toString()}
          />
        ),
      },
      {
        id: FIELDS.tank_po2,
        label: LABEL_MAP[FIELDS.tank_po2],
        value: tank_po2 ?? "-",
        field: (
          <NumberInput
            setFieldValue={form.setFieldValue}
            {...form.getInputProps(FIELDS.tank_po2)}
            min={0}
            name={FIELDS.tank_po2}
            step={0.1}
            disabled={form.values.dive_mode !== "nitrox"}
            value={(form.values.dive_mode !== "nitrox"
              ? ""
              : form.values.tank_po2
            )?.toString()}
          />
        ),
      },
    ];
  }

  if (isBulkEditing) {
    gasData = gasData.filter(({ id }) => BULK_EDITABLE_FIELDS.includes(id));
  }

  return (
    <div className={classes.tableSection}>
      <Title order={3} className={classes.tableHeading}>
        <IconGauge className={classes.tableHeadingIcon} />
        <span>Gas / air</span>
      </Title>
      <table className={classes.dataTable}>
        <tbody>
          {gasData.map(({ label, value, field }) => (
            <tr key={label} className={classes.dataTableRow}>
              <td className={classes.dataLabel}>{label}</td>
              <td className={classes.dataValue}>{isEditing ? field : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function ConservationFields({
  isEditing,
  loggedDive,
  form,
  setCurrentModal,
}: {
  isEditing: boolean;
  loggedDive?: Partial<LoggedDive> | null;
  form: UseFormReturnType<Partial<DiveLogFormInputs>>;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
}) {
  const { setFieldValue, setValues } = form;
  const { conservation_organisation_name, conservation_activities } =
    loggedDive || {};

  const setConservationOrganisationFields = useCallback(
    (
      diveCompanyFields: {
        id: string;
        company_name: string;
      } | null
    ) => {
      const { id, company_name } = diveCompanyFields ?? {};
      setValues({
        conservation_organisation_id: id || null,
        conservation_organisation_name: company_name || null,
      });
    },
    [setValues]
  );

  const activityPills =
    (conservation_activities as [])?.map((activity) => {
      const { label } =
        CONSERVATION_ACTIVITIES.find((a) => a.value === activity) || {};

      if (!label) return null;

      return (
        <li key={activity} className={classes.pill}>
          {label}
        </li>
      );
    }) || [];

  const conservationData = [
    {
      id: FIELDS.conservation_organisation_id,
      label: LABEL_MAP[FIELDS.conservation_organisation_id],
      value: conservation_organisation_name || "-",
      field: (
        <DiveCompanySearch
          hideLabel
          setDiveCompanyFields={setConservationOrganisationFields}
          placeholder="Search organisations..."
          fetcherKey="get-conservation-organisation"
          companyType={COMPANY_TYPES.CONSERVATION}
          diveCompanyNameInputProps={{
            ...form.getInputProps(FIELDS.conservation_organisation_name),
            name: FIELDS.conservation_organisation_name,
          }}
          onChange={(v) => {
            setFieldValue(FIELDS.conservation_organisation_name, v);
          }}
          onAddNewCompany={() => {
            setCurrentModal(MODALS.CREATE_ORGANISATION, {
              conservation_organisation_name:
                form.values.conservation_organisation_name,
              company_type: COMPANY_TYPES.CONSERVATION,
            });
          }}
        />
      ),
    },
    {
      id: FIELDS.conservation_activities,
      label: LABEL_MAP[FIELDS.conservation_activities],
      value: <ul className={classes.pills}>{activityPills}</ul>,
      field: (
        <MultiSelect
          name={FIELDS.conservation_activities}
          value={form.values.conservation_activities}
          onChange={(v) => setFieldValue(FIELDS.conservation_activities, v)}
          placeholder="Select activities..."
          data={[
            {
              group: "Coral",
              items: CORAL_ACTIVITES,
            },
            {
              group: "Marine life",
              items: MARINE_ACTIVITES,
            },
            {
              group: "Other",
              items: OTHER_ACTIVITES,
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div className={classes.tableSection}>
      <Title order={3} className={classes.tableHeading}>
        <IconFish className={classes.tableHeadingIcon} />
        <span>Conservation</span>
      </Title>
      <table className={classes.dataTable}>
        <tbody>
          {conservationData.map(({ label, value, field }) => (
            <tr key={label} className={classes.dataTableRow}>
              <td className={classes.dataLabel}>{label}</td>
              <td className={classes.dataValue}>{isEditing ? field : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function DeleteLogRow({
  show,
  handleOnDelete,
}: {
  show: boolean;
  handleOnDelete: () => void;
}) {
  if (!show) return null;

  return (
    <div className={classes.tableSection}>
      <Button variant="warn" ml="auto" mb="lg" mr="xs" onClick={handleOnDelete}>
        Delete
      </Button>
    </div>
  );
}

function LogOverview({
  loggedDive,
  isEditing,
  setIsEditing,
  userProfile,
  setIsFormDirty,
  isCurrentUserAuthor,
  currentModal,
  handleCheckCancel,
  onCancel,
  onClose,
  setShowLeaveWarning,
  showLeaveWarning,
  setCurrentModal,
  modalData,
  isBulkEditing,
  selectedLogs,
  selectedLogCount,
  bulkSubmitData,
  setBulkSubmitData,
  showDeleteWarn,
  setShowDeleteWarn,
}: {
  loggedDive?: Partial<LoggedDive> | null;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  userProfile: UserProfile;
  setIsFormDirty: (value: boolean) => void;
  isCurrentUserAuthor: boolean;
  currentModal: ModalTypes | null;
  handleCheckCancel: () => void;
  onCancel: () => void;
  onClose: (clearSelection?: boolean) => void;
  setShowLeaveWarning: (value: "close" | "cancel" | null) => void;
  showLeaveWarning: "close" | "cancel" | null;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  modalData?: ModalData;
  isBulkEditing?: boolean;
  selectedLogs?: { [key: string]: boolean };
  selectedLogCount?: number;
  bulkSubmitData: Partial<{
    [key in (typeof BULK_EDITABLE_FIELDS)[number]]: string | number;
  }> | null;
  setBulkSubmitData: Dispatch<
    SetStateAction<Partial<{
      [key in (typeof BULK_EDITABLE_FIELDS)[number]]: string | number;
    }> | null>
  >;
  showDeleteWarn: boolean;
  setShowDeleteWarn: Dispatch<SetStateAction<boolean>>;
}) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const bulkUpdateFetcher = useFetcher<typeof bulkUpdateAction>();
  const upsertFetcher = useFetcher<typeof upsertAction>();
  const deleteFetcher = useFetcher<typeof deleteAction>();

  const { logData } = useDiveLogData({
    preferences: userProfile,
    log: loggedDive ?? {},
  });

  const {
    preferred_temperature_unit,
    preferred_depth_unit,
    preferred_volume_unit,
    preferred_pressure_unit,
  } = userProfile || {
    preferred_temperature_unit: "celsius",
    preferred_depth_unit: "feet",
    preferred_volume_unit: "litres",
    preferred_pressure_unit: "bar",
  };

  const dive_site_name =
    loggedDive?.dive_sites?.dive_site_name ||
    loggedDive?.temp_dive_site_name ||
    null;
  const dive_site_id = logData?.dive_site_id ?? null;
  const company_id = logData?.company_id ?? null;
  const company_name = logData?.company_name ?? null;
  const conservation_organisation_id =
    logData?.conservation_organisation_id ?? null;
  const conservation_organisation_name =
    logData?.conservation_organisation_name ?? null;
  const entry_type = logData?.entry_type ?? null;
  const water_type = logData?.water_type ?? null;
  const visibility = logData?.visibility ?? 0;
  const current = logData?.current ?? 0;
  const surface_conditions = logData?.surface_conditions ?? 0;
  const dive_type = logData?.dive_type ?? null;
  const dive_mode = logData?.dive_mode ?? null;
  const tank_type = logData?.tank_type ?? null;
  const tank_size = logData?.tank_size || null;
  const max_depth = logData?.max_depth && Math.round(logData.max_depth);
  const tank_oxygen = logData?.tank_oxygen ?? null;
  const tank_po2 = logData?.tank_po2 ?? null;

  const form = useForm<Partial<DiveLogFormInputs>>({
    initialValues: {
      ...logData,
      dive_site_name,
      dive_site_id,
      company_id,
      company_name,
      conservation_organisation_id,
      conservation_organisation_name,
      max_depth,
      entry_type,
      water_type,
      visibility,
      current,
      surface_conditions,
      dive_type,
      dive_mode,
      tank_oxygen,
      tank_po2,
      tank_type,
      tank_size,
    },
  });

  const rootData = useRouteLoaderData("root");

  const { logged_trips } = (
    rootData as unknown as {
      userProfile: { logged_trips: PartialLoggedTrip[] };
    }
  )?.userProfile ?? { logged_trips: [] };

  const selectedTrip = logged_trips?.find(
    (trip) => trip.id === loggedDive?.trip_id
  );

  const isFormDirty = form.isDirty();
  const error =
    (upsertFetcher.state === "idle" && upsertFetcher.data?.error) ||
    (bulkUpdateFetcher.state === "idle" && bulkUpdateFetcher.data?.error);

  const currentLogId = loggedDive?.id;

  const handleBulkSubmitWarn = () => {
    if (formRef?.current) {
      const formData = new FormData(formRef.current);
      const values: {
        [key: string]: string | number | React.ReactNode | null;
      } = {};

      for (const [key, val] of formData.entries()) {
        const isDirty = form.isDirty(key);

        if (!isDirty) continue;

        if (typeof val === "string") {
          if (key === FIELDS.conservation_activities) {
            const items = form.values?.conservation_activities?.map(
              (activity) => {
                const { label } =
                  CONSERVATION_ACTIVITIES.find((a) => a.value === activity) ||
                  {};
                return <li key={activity}>{label}</li>;
              }
            );

            values[key as (typeof BULK_EDITABLE_FIELDS)[number]] = (
              <ul className={classes.bulkEditValueList}>{items}</ul>
            );
          } else {
            values[key as (typeof BULK_EDITABLE_FIELDS)[number]] = val;
          }
        }
      }

      setBulkSubmitData(values);
    }
  };

  const handleBulkSubmit = () => {
    if (bulkSubmitData) {
      const selectedDiveLogIds = Object.entries(selectedLogs ?? {}).flatMap(
        ([key, val]) => (val ? key : [])
      );

      const bulkFormData = new FormData();
      bulkFormData.append("dive_log_ids", JSON.stringify(selectedDiveLogIds));

      for (const [key, val] of Object.entries(bulkSubmitData)) {
        const isDirty = form.isDirty(key);
        if (isDirty) {
          if (key === FIELDS.conservation_activities) {
            bulkFormData.append(
              key,
              JSON.stringify(form.values.conservation_activities ?? [])
            );
            bulkFormData.append(
              "conservation_organisation_id",
              form.values.conservation_organisation_id ?? ""
            );
          } else {
            bulkFormData.append(key, val.toString());
          }
        }
      }

      bulkFormData.append(
        "preferred_temperature_unit",
        preferred_temperature_unit
      );
      bulkFormData.append("preferred_depth_unit", preferred_depth_unit);
      bulkFormData.append("preferred_volume_unit", preferred_volume_unit);
      bulkFormData.append("preferred_pressure_unit", preferred_pressure_unit);

      if (form.values.entry_date) {
        bulkFormData.set(
          "entry_date",
          getDateOnly(form.values.entry_date) ?? ""
        );
      }

      bulkUpdateFetcher.submit(bulkFormData, {
        method: "post",
        action: "/bulk-update-dive-logs",
      });
    }
  };

  const handleSubmit = () => {
    if (formRef?.current) {
      const formData = new FormData(formRef.current);

      formData.append("company_id", form.values.company_id ?? "");
      formData.append(
        "conservation_organisation_id",
        form.values.conservation_organisation_id ?? ""
      );

      formData.set(
        "conservation_activities",
        JSON.stringify(form.values.conservation_activities ?? [])
      );

      formData.append("preferred_temperature_unit", preferred_temperature_unit);
      formData.append("preferred_depth_unit", preferred_depth_unit);
      formData.append("preferred_volume_unit", preferred_volume_unit);
      formData.append("preferred_pressure_unit", preferred_pressure_unit);

      if (currentLogId) {
        formData.append("dive_log_id", currentLogId);
      }

      if (form.values.entry_date) {
        formData.set("entry_date", getDateOnly(form.values.entry_date) ?? "");
      }

      upsertFetcher.submit(formData, {
        method: "post",
        action: "/update-dive-logs",
      });
    }
  };

  const handleDelete = () => {
    const formData = new FormData();

    if (currentLogId) {
      formData.append("dive_log_ids", JSON.stringify([currentLogId]));
    } else {
      const selectedDiveLogIds = Object.entries(selectedLogs ?? {}).flatMap(
        ([key, val]) => (val ? key : [])
      );
      formData.append("dive_log_ids", JSON.stringify(selectedDiveLogIds));
    }

    deleteFetcher.submit(formData, {
      method: "delete",
      action: "/delete-dive-logs",
    });
  };

  const getFieldValue = (key: string, value: string | number) => {
    if (key === FIELDS.dive_site_id) {
      return form.values.dive_site_name;
    }

    if (key === FIELDS.trip_id) {
      return <TripHeading trip={selectedTrip} />;
    }

    if (key === FIELDS.company_id) {
      return form.values.company_name;
    }

    if (key === FIELDS.entry_date) {
      return dayjs(value).format("D MMM YYYY");
    }

    if (
      key === FIELDS.min_water_temp ||
      key === FIELDS.max_water_temp ||
      key === FIELDS.air_temp
    ) {
      return `${value} ${temperature[preferred_temperature_unit]}`;
    }

    if (key === FIELDS.visibility) {
      return VISIBILITY_LABELS[Number(value)];
    }

    if (key === FIELDS.current) {
      return CURRENT_LABELS[Number(value)];
    }

    if (key === FIELDS.surface_conditions) {
      return SURFACE_CONDITION_LABELS[Number(value)];
    }

    if (key === FIELDS.tank_size) {
      return `${value} ${volume[preferred_volume_unit]}`;
    }

    if (key === FIELDS.tank_oxygen) {
      return `${value}%`;
    }

    if (key === FIELDS.tank_po2) {
      return value;
    }

    return value;
  };

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [setIsFormDirty, isFormDirty]);

  const { setValues } = form;

  useEffect(() => {
    if (modalData) {
      setValues(modalData);
    }
  }, [modalData, setValues]);

  useEffect(
    function onUpsertSuccess() {
      const { diveLogId } = upsertFetcher.data ?? { diveLogId: null };
      if (
        upsertFetcher.state === "idle" &&
        diveLogId &&
        !error &&
        isFormDirty
      ) {
        showNotication("Dive log successfully updated", "success");
        setIsEditing(false);
      }
    },
    [upsertFetcher.state, upsertFetcher.data, error, isFormDirty, setIsEditing]
  );

  useEffect(
    function onBulkUpdateSuccess() {
      const { success } = bulkUpdateFetcher.data || {};
      if (
        bulkUpdateFetcher.state === "idle" &&
        success &&
        !error &&
        isFormDirty &&
        bulkSubmitData
      ) {
        showNotication("Dive logs successfully updated", "success");
        setBulkSubmitData(null);
        onClose(true);
      }
    },
    [
      bulkUpdateFetcher.state,
      bulkUpdateFetcher.data,
      error,
      isFormDirty,
      currentModal,
      setBulkSubmitData,
      onClose,
      bulkSubmitData,
    ]
  );

  useEffect(
    function onDeleteSuccess() {
      const { deleted } = deleteFetcher.data || {};

      if (
        deleteFetcher.state === "idle" &&
        deleted &&
        !error &&
        showDeleteWarn
      ) {
        revalidator.revalidate();
        showNotication(
          `Dive log${currentLogId ? "" : "s"} successfully deleted`,
          "success"
        );
        onClose(true);
        setShowDeleteWarn(false);
        setIsEditing(false);

        if (currentLogId) {
          navigate(`${PROFILE}/${userProfile.id}`, {
            replace: true,
          });
        }
      }
    },
    [
      deleteFetcher.state,
      deleteFetcher.data,
      error,
      onClose,
      currentLogId,
      navigate,
      userProfile.id,
      showDeleteWarn,
      setShowDeleteWarn,
      setIsEditing,
      revalidator,
    ]
  );

  useEffect(
    function onError() {
      if (error) {
        showNotication(`Failed to save: ${error}`, "error");
      }
    },
    [error]
  );

  if (bulkSubmitData) {
    return (
      <Container
        footer={
          <Row>
            <Button
              variant="light"
              style={{ flex: 1 }}
              onClick={() => {
                setBulkSubmitData(null);
              }}
              disabled={bulkUpdateFetcher.state !== "idle"}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              style={{ flex: 1 }}
              onClick={() => handleBulkSubmit()}
              loading={bulkUpdateFetcher.state !== "idle"}
              disabled={bulkUpdateFetcher.state !== "idle"}
            >
              Submit
            </Button>
          </Row>
        }
      >
        <Text
          size="h4"
          style={{
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          The following values will be applied to{" "}
          <strong
            style={{
              textDecoration: "underline",
            }}
          >
            {selectedLogCount} dive logs
          </strong>
          . Please double check before submitting.
        </Text>

        <div className={classes.tableSection}>
          <table className={classes.dataTable}>
            <tbody>
              {Object.entries(bulkSubmitData).map(([key, value]) => (
                <tr key={key} className={classes.dataTableRow}>
                  <td className={classes.dataLabel}>
                    {LABEL_MAP[key as keyof typeof LABEL_MAP]}
                  </td>
                  <td className={classes.dataValue}>
                    {getFieldValue(key, value)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    );
  }

  if (showLeaveWarning) {
    return (
      <Container
        footer={
          <Row>
            <Button
              variant="light"
              style={{ flex: 1 }}
              onClick={() => {
                setShowLeaveWarning(null);
              }}
              disabled={upsertFetcher.state !== "idle"}
            >
              No thanks
            </Button>
            <Button
              variant="default"
              style={{ flex: 1 }}
              onClick={() => {
                if (showLeaveWarning === "cancel") {
                  onCancel();
                } else {
                  onClose();
                }
              }}
              disabled={upsertFetcher.state !== "idle"}
            >
              Yes please
            </Button>
          </Row>
        }
      >
        <Title
          order={2}
          size="h3"
          style={{
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          You have unsaved changes. Are you sure you want to{" "}
          {showLeaveWarning === "close" ? "leave" : "cancel"}?
        </Title>
      </Container>
    );
  }

  if (showDeleteWarn) {
    return (
      <Container
        footer={
          <Row>
            <Button
              variant="light"
              style={{ flex: 1 }}
              onClick={() => {
                setShowDeleteWarn(false);
              }}
              disabled={deleteFetcher.state !== "idle"}
            >
              No thanks
            </Button>
            <Button
              variant="warn"
              style={{ flex: 1 }}
              onClick={handleDelete}
              disabled={deleteFetcher.state !== "idle"}
              loading={deleteFetcher.state !== "idle"}
            >
              Yes please
            </Button>
          </Row>
        }
      >
        <Title
          order={2}
          size="h3"
          style={{
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          {currentLogId
            ? "Are you sure you want to delete this dive log?"
            : `Are you sure you want to delete these ${selectedLogCount} dive logs?`}
        </Title>
      </Container>
    );
  }

  return (
    <Form ref={formRef}>
      {isCurrentUserAuthor || isBulkEditing ? (
        <>
          <div className={classes.formCTAs}>
            {isEditing ? (
              <>
                <Button
                  variant="outline"
                  disabled={upsertFetcher.state !== "idle"}
                  onClick={handleCheckCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="default"
                  loading={upsertFetcher.state !== "idle"}
                  disabled={upsertFetcher.state !== "idle" || !isFormDirty}
                  onClick={() => {
                    if (isBulkEditing) {
                      handleBulkSubmitWarn();
                    } else {
                      handleSubmit();
                    }
                  }}
                >
                  Save
                </Button>
              </>
            ) : null}
          </div>
          {isBulkEditing ? (
            <Text span ml="auto" className={classes.bulkEditSubHeading}>
              (Only showing fields that can be bulk edited)
            </Text>
          ) : null}
        </>
      ) : null}

      <div className={classes.content}>
        <GeneralFields
          form={form}
          loggedDive={loggedDive}
          isEditing={isEditing}
          preferences={userProfile}
          selectedTrip={selectedTrip}
          loggedTrips={logged_trips}
          setCurrentModal={setCurrentModal}
          isBulkEditing={isBulkEditing}
        />
        {form.values.dive_type === "conservation" && (
          <ConservationFields
            form={form}
            loggedDive={loggedDive}
            isEditing={isEditing}
            setCurrentModal={setCurrentModal}
          />
        )}
        <ConditionsFields
          form={form}
          loggedDive={loggedDive}
          isEditing={isEditing}
          preferences={userProfile}
          isBulkEditing={isBulkEditing}
        />
        <GasAirFields
          form={form}
          loggedDive={loggedDive}
          isEditing={isEditing}
          preferences={userProfile}
          isBulkEditing={isBulkEditing}
        />
        <DeleteLogRow
          show={Boolean(isEditing || isBulkEditing)}
          handleOnDelete={() => setShowDeleteWarn(true)}
        />
      </div>
    </Form>
  );
}

export function DiveLogModal({
  loggedDive,
  loggedDiveSample,
  photos,
  onClose,
  basePath,
  nextUrl,
  prevUrl,
  diveNumberKey,
  isBulkEditing = false,
  selectedLogCount,
  selectedLogs,
}: {
  loggedDive?: Partial<LoggedDive> | null;
  loggedDiveSample?: DiveSample[] | null;
  photos: GalleryPhoto[];
  onClose: (clearSelection?: boolean) => void;
  basePath: string;
  nextUrl?: string | null;
  prevUrl?: string | null;
  diveNumberKey?:
    | typeof COLUMN_IDS.DIVE_NUMBER
    | typeof COLUMN_IDS.TRIP_DIVE_NUMBER;
  isBulkEditing?: boolean;
  selectedLogCount?: number;
  selectedLogs?: {
    [key: string]: boolean;
  };
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteWarn, setShowDeleteWarn] = useState(false);
  const [showLeaveWarning, setShowLeaveWarning] = useState<
    "close" | "cancel" | null
  >(null);
  const { photoId } = useParams();
  const {
    userProfile,
    currentModal,
    setCurrentModal,
    modalData,
    diveLogModalTab,
    setDiveLogModalTab,
    prefetchType,
  } = useOutletContext<OutletContext>();

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [bulkSubmitData, setBulkSubmitData] = useState<Partial<{
    [key in (typeof BULK_EDITABLE_FIELDS)[number]]: string | number;
  }> | null>(null);

  const { author_id, avatar_image_urls, user_profiles } = loggedDive || {};

  const isCurrentUserAuthor = userProfile?.id === author_id;

  const [fetcherKey, setFetcherKey] = useState(0);

  useEffect(() => {
    setFetcherKey((prev) => prev + 1);
  }, [isEditing]);

  useEffect(() => {
    setIsEditing(isBulkEditing);
  }, [isBulkEditing]);

  const handleCancel = () => {
    setIsEditing(false);
    setShowLeaveWarning(null);

    if (isBulkEditing) {
      onClose();
    }
  };

  const handleClose = (clearSelection?: boolean) => {
    onClose(clearSelection);
    setTimeout(() => {
      setShowLeaveWarning(null);
      setIsEditing(false);
    }, 200);
  };

  const handleCheckCancel = () => {
    if (isFormDirty) {
      setShowLeaveWarning("cancel");
    } else {
      handleCancel();
    }
  };

  const handleCheckClose = () => {
    if (isFormDirty) {
      setShowLeaveWarning("close");
    } else {
      handleCancel();
      onClose();
    }
  };

  const hideModal = Boolean(
    photoId ||
      currentModal === MODALS.UPLOAD_DIVE_PHOTOS ||
      currentModal === MODALS.DIVE_SITE ||
      currentModal === MODALS.CREATE_ORGANISATION
  );

  const { username, id: userId } = user_profiles || {};

  const diveNumber = diveNumberKey ? loggedDive?.[diveNumberKey] : null;

  return (
    <Modal
      isWide={!showLeaveWarning && !showDeleteWarn}
      isShort={Boolean(showLeaveWarning || showDeleteWarn)}
      opened={Boolean(loggedDive?.id) || isBulkEditing}
      hidden={hideModal}
      onClose={handleCheckClose}
      closeOnClickOutside={!isEditing && !bulkSubmitData}
      withCloseButton={
        !showLeaveWarning && !isEditing && !bulkSubmitData && !showDeleteWarn
      }
      header={
        !showLeaveWarning &&
        !showDeleteWarn && (
          <div className={classes.headerContent}>
            {isBulkEditing ? (
              <>
                <IconTitle icon={<IconPencil />} title="Bulk edit" />
                {!bulkSubmitData && (
                  <Text span size="16px" mb="xs" className={classes.diveNum}>
                    Editing {selectedLogCount} dive logs...
                  </Text>
                )}
              </>
            ) : (
              <Title mb="sm">
                {diveNumber && (
                  <Text span size="16px" mb="xs" className={classes.diveNum}>
                    Dive no. {diveNumber}
                  </Text>
                )}
                {loggedDive ? (
                  <>
                    <Text span mb="xs" ml="-1" className={classes.subHeading}>
                      {getDiveLogLocation(loggedDive)}
                    </Text>
                    <Text
                      span
                      size="45px"
                      ml="-2.5"
                      className={classes.headingText}
                    >
                      {getDiveSiteName(loggedDive)}
                    </Text>
                  </>
                ) : null}
              </Title>
            )}
            {userId ? (
              <Link
                to={`${PROFILE}/${userId}`}
                className={classes.profileLink}
                prefetch={prefetchType}
              >
                <UserAvatar
                  username={username}
                  size={26}
                  imageUrl={avatar_image_urls?.small_url}
                />
                <span className={classes.profileLinkText}>{username}</span>
              </Link>
            ) : null}
            <Row>
              {!isEditing && (
                <>
                  <CookieTabs
                    value={diveLogModalTab}
                    setValue={(v) =>
                      setDiveLogModalTab(v as DIVE_LOG_MODAL_TABS_TYPES)
                    }
                    options={[
                      {
                        value: OVERVIEW,
                        label: "Overview",
                      },
                      {
                        value: DIVE_SAMPLES,
                        label: "Dive samples",
                      },
                      {
                        value: GALLERY,
                        label: "Gallery",
                      },
                    ]}
                    cookieKey={DIVE_LOG_MODAL_TAB}
                  />

                  {diveLogModalTab === OVERVIEW && isCurrentUserAuthor && (
                    <EditButton
                      onClick={() => {
                        setIsEditing(true);
                      }}
                      label="Edit dive log"
                    />
                  )}
                </>
              )}
            </Row>
          </div>
        )
      }
      footer={
        !isEditing &&
        !showLeaveWarning && (
          <div className={classes.footer}>
            <Button
              className={classes.paginationButton}
              variant="default"
              renderRoot={(props) =>
                prevUrl ? (
                  <Link
                    to={prevUrl}
                    replace
                    preventScrollReset
                    prefetch="render"
                    {...props}
                  />
                ) : (
                  <span {...props} />
                )
              }
              leftSection={<IconArrowLeft size={16} />}
              disabled={!prevUrl}
            >
              Previous
            </Button>
            <Button
              className={classes.paginationButton}
              variant="default"
              renderRoot={(props) =>
                nextUrl ? (
                  <Link
                    to={nextUrl}
                    replace
                    preventScrollReset
                    prefetch="render"
                    {...props}
                  />
                ) : (
                  <span {...props} />
                )
              }
              rightSection={<IconArrowRight size={16} />}
              disabled={!nextUrl}
            >
              Next
            </Button>
          </div>
        )
      }
    >
      {diveLogModalTab === OVERVIEW ? (
        <LogOverview
          // We need to ensure the form is completely reset,
          // and simplest way to do this is to is to remount it
          key={`${loggedDive?.id}--${fetcherKey}`}
          loggedDive={loggedDive}
          userProfile={userProfile}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setIsFormDirty={setIsFormDirty}
          isCurrentUserAuthor={isCurrentUserAuthor}
          currentModal={currentModal}
          handleCheckCancel={handleCheckCancel}
          setShowLeaveWarning={setShowLeaveWarning}
          showLeaveWarning={showLeaveWarning}
          onCancel={handleCancel}
          onClose={handleClose}
          setCurrentModal={setCurrentModal}
          modalData={modalData}
          isBulkEditing={isBulkEditing}
          selectedLogs={selectedLogs}
          selectedLogCount={selectedLogCount}
          bulkSubmitData={bulkSubmitData}
          setBulkSubmitData={setBulkSubmitData}
          showDeleteWarn={showDeleteWarn}
          setShowDeleteWarn={setShowDeleteWarn}
        />
      ) : null}

      {diveLogModalTab === DIVE_SAMPLES ? (
        <div className={classes.contentGraph}>
          <SampleGraph
            loggedDiveSample={loggedDiveSample ?? []}
            userProfile={userProfile}
          />
        </div>
      ) : null}

      {diveLogModalTab === GALLERY ? (
        <div className={classes.content}>
          <ImageList
            selectedPhotoId={photoId}
            basePath={basePath}
            photos={photos || []}
            uploadCTA={
              isCurrentUserAuthor && (
                <Button
                  onClick={() => {
                    setCurrentModal(MODALS.UPLOAD_DIVE_PHOTOS);
                  }}
                  leftSection={<IconUpload size={16} />}
                >
                  Upload photos
                </Button>
              )
            }
          />
        </div>
      ) : null}
    </Modal>
  );
}
