import { useEffect } from "react";
import { useFetcher } from "@remix-run/react";
import {
  AutocompleteProps,
  Group,
  TextInput,
  Text,
  Loader,
} from "@mantine/core";
import { Autocomplete } from "~/components/_common/form/Autocomplete/Autocomplete";
import { loader } from "~/routes/_api.get-dive-sites";
import { vars } from "~/config/theme";
import { GET_DIVE_SITES } from "~/config/api";

import * as classes from "./DiveSiteSearch.css";

export function DiveSiteSearch({
  diveSiteIdInputProps,
  setDiveSiteId,
  diveSiteNameInputProps,
  onChange,
  isTempDiveSiteName,
  onAddNewDiveSite,
}: {
  diveSiteIdInputProps: React.ComponentProps<typeof TextInput>;
  setDiveSiteId: (id: string) => void;
  diveSiteNameInputProps: React.ComponentProps<typeof Autocomplete>;
  onChange: (value: string) => void;
  isTempDiveSiteName?: boolean;
  onAddNewDiveSite?: () => void;
}) {
  const value = diveSiteNameInputProps.value;
  const { load, data, state } = useFetcher<typeof loader>({
    key: `get-dive-sites-${value}-${diveSiteIdInputProps.value}`,
  });
  const { results, error: searchError } = data || {};

  const noResultsMessage =
    state === "idle" && results?.length === 0
      ? `No results containing "${value}"`
      : null;

  const errorMessage = isTempDiveSiteName
    ? "Temporary dive site detected."
    : diveSiteIdInputProps.error || searchError || noResultsMessage;

  const selectedDiveSiteId = results?.find(
    ({ dive_site_name }) => value === dive_site_name
  )?.id;

  const renderAutocompleteOption: AutocompleteProps["renderOption"] = ({
    option,
  }) => {
    if (errorMessage) {
      return (
        <button
          type="button"
          className={classes.addDiveSiteButton}
          onClick={() => onAddNewDiveSite?.()}
        >
          <Text size="xs" mb="xs">
            {errorMessage}
          </Text>
          <Text size="sm">Click here to add it...</Text>
        </button>
      );
    }

    const item = results?.find(({ id }) => id === option.value);

    if (!item) return null;

    return (
      <Group gap="sm">
        <div>
          <Text size="sm">{item.dive_site_name}</Text>
          <Text size="xs" opacity={0.7}>
            {item.location}
          </Text>
        </div>
      </Group>
    );
  };

  const ids = results?.map(({ dive_site_name, id }) => ({
    value: id,
    label: dive_site_name,
  }));

  useEffect(() => {
    if (selectedDiveSiteId) {
      setDiveSiteId(selectedDiveSiteId);
    }
  }, [setDiveSiteId, selectedDiveSiteId]);

  useEffect(() => {
    if (value && value.length) {
      load(`${GET_DIVE_SITES}?search=${value}`);
    } else {
      setDiveSiteId("");
    }
  }, [setDiveSiteId, load, value]);

  return (
    <div className={classes.container}>
      <Autocomplete
        required
        data={
          ids?.length
            ? ids
            : value
            ? // This is a hack to show an error message in the dropdown
              [{ value: "error", label: value! }]
            : []
        }
        renderOption={renderAutocompleteOption}
        maxDropdownHeight={300}
        placeholder="Search dive sites..."
        value={value ?? ""}
        onChange={onChange}
        error={Boolean(errorMessage)}
        {...(state === "idle" ? {} : { dropdownOpened: false })}
      />
      <TextInput
        {...diveSiteIdInputProps}
        value={diveSiteIdInputProps.value ?? ""}
        type="hidden"
        error={false}
      />
      {state !== "idle" ? (
        <Loader
          className={classes.loader}
          size="xs"
          color={vars.colors.dark[4]}
        />
      ) : null}
    </div>
  );
}
