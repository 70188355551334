import { DatePickerInput as MatineDateInput } from "@mantine/dates";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as classes from "./DateInput.css";

dayjs.extend(utc);

export function DateInput(props: React.ComponentProps<typeof MatineDateInput>) {
  return (
    <MatineDateInput
      {...props}
      value={
        typeof props.value === "string" ? new Date(props.value) : props.value
      }
      popoverProps={{
        classNames: {
          dropdown: classes.dropdown,
        },
      }}
      classNames={{
        input: classes.input,
        day: classes.day,
        calendarHeaderLevel: classes.calendarHeaderLevel,
        calendarHeaderControl: classes.calendarHeaderControl,
      }}
    />
  );
}
