import { useMemo } from "react";
import { UNIT_DEFAULTS } from "~/config/units";
import {
  barToPsi,
  celsiusToFahrenheit,
  litresToCubicFeet,
  metresToFeet,
} from "../conversions";
import { DiveSample } from "~/config/dive-log";
import { DB } from "~/types/shared";

const getIsValidNumber = (n: unknown) =>
  typeof n === "number" && n !== Infinity && n !== -Infinity;

function round5(x: number) {
  return Math.ceil(x / 5) * 5;
}

export type DiveSampleData = ReturnType<typeof useDiveLogData>["sampleData"];
export type DiveSampleDomains = ReturnType<
  typeof useDiveLogData
>["diveSampleDomains"];

export function useDiveLogData({
  samples,
  log,
  preferences,
}: {
  samples?: DiveSample[];
  log?: Partial<DB["logged_dives"]["Row"]> & {
    conservation_activities?: string[];
  };
  preferences: Partial<DB["user_profiles"]["Row"]>;
}) {
  const convertedSampleData = useMemo(() => {
    if (!samples) return null;

    return samples.map(({ water_temp, depth, tank_pressure, ...rest }) => {
      const {
        preferred_depth_unit,
        preferred_temperature_unit,
        preferred_pressure_unit,
      } = preferences;

      return {
        ...rest,
        tank_pressure:
          typeof tank_pressure === "number"
            ? preferred_pressure_unit === UNIT_DEFAULTS.PRESSURE
              ? tank_pressure
              : Math.round(barToPsi(tank_pressure))
            : undefined,
        water_temp:
          typeof water_temp === "number"
            ? preferred_temperature_unit === UNIT_DEFAULTS.TEMPERATURE
              ? water_temp
              : celsiusToFahrenheit(water_temp)
            : undefined,
        depth:
          typeof depth === "number"
            ? preferred_depth_unit === UNIT_DEFAULTS.DEPTH
              ? depth
              : metresToFeet(depth)
            : undefined,
      };
    });
  }, [samples, preferences]);

  const diveSampleDomains = useMemo(() => {
    if (!convertedSampleData) return null;
    return convertedSampleData?.reduce(
      (acc, curr) => {
        if (typeof curr.depth === "number") {
          if (!acc.depth) {
            acc.depth = [curr.depth, round5(curr.depth)];
          }

          if (curr.depth < acc.depth[0]) {
            acc.depth[0] = Math.round(curr.depth);
          }

          if (curr.depth > acc.depth[1]) {
            acc.depth[1] = round5(curr.depth);
          }
        }

        if (typeof curr.water_temp === "number") {
          const waterTemp = Math.round(curr.water_temp);

          if (!acc.water_temp) {
            acc.water_temp = [waterTemp, waterTemp];
          }

          if (
            acc.water_temp[0] === null ||
            curr.water_temp < acc.water_temp[0]
          ) {
            acc.water_temp[0] = waterTemp;
          }

          if (
            acc.water_temp[1] === null ||
            curr.water_temp > acc.water_temp[1]
          ) {
            acc.water_temp[1] = waterTemp;
          }
        }

        if (typeof curr.heart_rate === "number") {
          if (!acc.heart_rate) {
            acc.heart_rate = [curr.heart_rate, curr.heart_rate];
          }

          if (curr.heart_rate < acc.heart_rate[0]) {
            acc.heart_rate[0] = curr.heart_rate;
          }

          if (curr.heart_rate > acc.heart_rate[1]) {
            acc.heart_rate[1] = curr.heart_rate;
          }
        }

        if (typeof curr.tank_pressure === "number") {
          if (!acc.tank_pressure) {
            acc.tank_pressure = [curr.tank_pressure, curr.tank_pressure];
          }

          if (curr.tank_pressure < acc.tank_pressure[0]) {
            acc.tank_pressure[0] = curr.tank_pressure;
          }

          if (curr.tank_pressure > acc.tank_pressure[1]) {
            acc.tank_pressure[1] = curr.tank_pressure;
          }
        }

        return acc;
      },
      {} as {
        depth?: [number, number];
        water_temp?: [number, number];
        heart_rate?: [number, number];
        tank_pressure?: [number, number];
      }
    );
  }, [convertedSampleData]);

  const formattedSampleData = useMemo(() => {
    if (!convertedSampleData) return null;

    return convertedSampleData?.reduce(
      (prev, curr) => {
        const { depth, water_temp, tank_pressure, heart_rate, elapsed_time } =
          curr;

        if (typeof elapsed_time !== "number") return prev;

        if (typeof depth === "number") {
          if (!prev.depth) {
            prev.depth = [];
          }

          prev.depth.push({ depth, elapsed_time });
        } else {
          const prevValue = prev.depth?.[prev.depth.length - 1].depth;

          if (typeof prevValue === "number") {
            prev.depth?.push({
              depth: prevValue,
              elapsed_time,
            });
          }
        }

        if (typeof water_temp === "number") {
          if (!prev.water_temp) {
            prev.water_temp = [];
          }

          prev.water_temp.push({ water_temp, elapsed_time });
        } else {
          const prevValue =
            prev.water_temp?.[prev.water_temp.length - 1].water_temp;

          if (typeof prevValue === "number") {
            prev.water_temp?.push({
              water_temp: prevValue,
              elapsed_time,
            });
          }
        }

        if (typeof tank_pressure === "number") {
          if (!prev.tank_pressure) {
            prev.tank_pressure = [];
          }

          prev.tank_pressure.push({ tank_pressure, elapsed_time });
        } else {
          const prevValue =
            prev.tank_pressure?.[prev.tank_pressure.length - 1].tank_pressure;

          if (typeof prevValue === "number") {
            prev.tank_pressure?.push({
              tank_pressure: prevValue,
              elapsed_time,
            });
          }
        }

        if (typeof heart_rate === "number") {
          if (!prev.heart_rate) {
            prev.heart_rate = [];
          }

          prev.heart_rate.push({ heart_rate, elapsed_time });
        } else {
          const prevValue =
            prev.heart_rate?.[prev.heart_rate.length - 1].heart_rate;

          if (typeof prevValue === "number") {
            prev.heart_rate?.push({
              heart_rate: prevValue,
              elapsed_time,
            });
          }
        }

        return prev;
      },
      {} as {
        depth?: { depth: number; elapsed_time: number }[];
        water_temp?: { water_temp: number; elapsed_time: number }[];
        tank_pressure?: { tank_pressure: number; elapsed_time: number }[];
        heart_rate?: { heart_rate: number; elapsed_time: number }[];
      }
    );
  }, [convertedSampleData]);

  const logData = useMemo(() => {
    if (!log || !Object.keys(log).length) return null;

    const {
      min_water_temp,
      max_water_temp,
      air_temp,
      max_depth,
      pressure_start,
      pressure_end,
      tank_size,
      ...rest
    } = log;
    const {
      preferred_depth_unit,
      preferred_temperature_unit,
      preferred_pressure_unit,
      preferred_volume_unit,
    } = preferences;

    return {
      ...rest,
      min_water_temp: getIsValidNumber(min_water_temp)
        ? preferred_temperature_unit === UNIT_DEFAULTS.TEMPERATURE
          ? min_water_temp
          : celsiusToFahrenheit(min_water_temp!)
        : undefined,
      max_water_temp: getIsValidNumber(max_water_temp)
        ? preferred_temperature_unit === UNIT_DEFAULTS.TEMPERATURE
          ? max_water_temp
          : celsiusToFahrenheit(max_water_temp!)
        : undefined,
      air_temp: getIsValidNumber(air_temp)
        ? preferred_temperature_unit === UNIT_DEFAULTS.TEMPERATURE
          ? air_temp
          : celsiusToFahrenheit(air_temp!)
        : undefined,
      max_depth: getIsValidNumber(max_depth)
        ? preferred_depth_unit === UNIT_DEFAULTS.DEPTH
          ? max_depth
          : metresToFeet(max_depth!)
        : undefined,
      pressure_start: getIsValidNumber(pressure_start)
        ? preferred_pressure_unit === UNIT_DEFAULTS.PRESSURE
          ? pressure_start
          : barToPsi(pressure_start!)
        : undefined,
      pressure_end: getIsValidNumber(pressure_end)
        ? preferred_pressure_unit === UNIT_DEFAULTS.PRESSURE
          ? pressure_end
          : barToPsi(pressure_end!)
        : undefined,
      tank_size: getIsValidNumber(tank_size)
        ? preferred_volume_unit === UNIT_DEFAULTS.VOLUME
          ? tank_size
          : litresToCubicFeet(tank_size!)
        : undefined,
    };
  }, [preferences, log]);

  return {
    logData,
    sampleData: formattedSampleData,
    diveSampleDomains,
  };
}
