import dayjs from "dayjs";
import { Group, Text, type SelectProps } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconCheck } from "@tabler/icons-react";
import { PartialLoggedTrip } from "~/types/shared";
import { Select } from "~/components/_common/form/Select/Select";
import { DiveLogFormInputs } from "~/config/dive-log";
import * as classes from "./TripSelect.css";

export function TripSelect({
  trips,
  selectProps,
  form,
}: {
  trips?: PartialLoggedTrip[];
  selectProps: SelectProps & { label?: string };
  form?:
    | UseFormReturnType<Partial<DiveLogFormInputs>>
    | UseFormReturnType<{
        log_id: string | undefined;
        trip_id: string | undefined;
      }>
    | UseFormReturnType<{
        dive_site_id: string;
        dive_site_name: string;
        trip_id: string;
        company_name: string;
        company_id: string;
      }>;
}) {
  const tripOptions = trips?.map(({ id, location }) => ({
    value: id,
    label: location,
  }));

  const renderTripOptions: SelectProps["renderOption"] = ({ option }) => {
    const item = trips?.find(({ id }) => id === option.value);

    if (!item) {
      return null;
    }

    const startDate = dayjs(item.start_date).format("DD MMM YYYY");
    const endDate = item.end_date && dayjs(item.end_date).format("DD MMM YYYY");
    const isSelected = form?.values.trip_id === option.value;

    return (
      <Group gap="sm" className={classes.itemContainer}>
        <div className={classes.itemText}>
          <Text size="sm">{item.location}</Text>
          <Text size="xs" opacity={0.7}>
            {startDate} {endDate ? ` - ${endDate}` : ""}
          </Text>
        </div>
        {isSelected && <IconCheck size="25" />}
      </Group>
    );
  };

  return (
    <div>
      <Select
        data={[
          {
            value: "",
            label: "",
          },
          ...(tripOptions ?? []),
        ]}
        defaultValue={""}
        name="trip_id"
        placeholder="Select from your trips..."
        style={{ width: "100%" }}
        renderOption={renderTripOptions}
        disabled={!trips?.length}
        allowDeselect
        {...selectProps}
      />
    </div>
  );
}
