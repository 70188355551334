import { useCallback } from "react";
import { ResponsiveContainer } from "recharts";
import type { AnimationTiming } from "recharts/types/util/types";
import { AreaChart } from "@mantine/charts";
import { Text } from "@mantine/core";
import { distance, pressure, temperature } from "~/config/abbreviations";
import { DB } from "~/types/shared";
import "@mantine/charts/styles.css";
import {
  DiveSampleData,
  DiveSampleDomains,
} from "~/utils/hooks/useDiveLogData";
import { secondsToTime } from "~/utils/conversions";
import { theme } from "~/config/theme";
import * as classes from "./LineGraph.css";

type Props = {
  data?: DiveSampleData;
  domains?: DiveSampleDomains;
  preferences: DB["user_profiles"]["Row"];
};

const margin = {
  top: 12,
  right: 20,
  left: 10,
  bottom: 20,
};

const colors = {
  depth: theme.colors?.snDarkBlue03?.[0],
  water_temp: theme.colors?.snWhite40?.[0],
  tank_pressure: theme.colors?.snLightLime01?.[0],
  heart_rate: "red",
};

const commonGraphProps = {
  gridAxis: "none",
  dataKey: "elapsed_time",
  strokeWidth: 1,
  curveType: "linear",
  tickLine: "xy",
  withXAxis: true,
  yAxisLabel: "Depth",
  xAxisLabel: "Elapsed Time",
  areaChartProps: { margin, syncId: "line-graph" },
  dotProps: {
    r: 0,
  },
  xAxisProps: {
    className: classes.hidden,
  },
};

const areaProps = {
  isAnimationActive: true,
  animationBegin: 0,
  animationDuration: 300,
  animationEasing: "ease-in-out" as AnimationTiming,
};

const commonYAxisProps = {
  width: 25,
  className: classes.hidden,
  padding: {
    top: 0,
    bottom: 0,
  },
};

const labelMap = {
  depth: "Depth",
  water_temp: "Water temp",
  tank_pressure: "Tank pressure",
  heart_rate: "Heart Rate",
} as const;

const TooltipRow = ({
  value,
  color,
  label,
  unit,
}: {
  value: number;
  color: string;
  label: string;
  unit: string;
}) => {
  return (
    <>
      <div className={classes.tooltipRow}>
        <span
          className={classes.tooltipIcon}
          style={{
            backgroundColor: color,
          }}
        />
        <Text
          className={classes.tooltipText}
          size="xs"
          component="span"
          style={{ minWidth: "80px" }}
        >
          {label}:
        </Text>
        <div>
          <Text className={classes.tooltipText} size="sm" component="span">
            {Math.round(value).toLocaleString()}
          </Text>{" "}
          <Text className={classes.tooltipText} size="xs" component="span">
            {unit}
          </Text>
        </div>
      </div>
    </>
  );
};

export function LineGraph({ data, domains, preferences }: Props) {
  const {
    preferred_depth_unit,
    preferred_temperature_unit,
    preferred_pressure_unit,
  } = preferences;

  const {
    depth: depthData,
    water_temp: waterTempData,
    tank_pressure: tankPressureData,
    heart_rate: heartRateData,
  } = data || {};
  const {
    depth: depthDomain,
    water_temp: waterTempDomain,
    tank_pressure: tankPressureDomain,
    heart_rate: heartRateDomain,
  } = domains || {};

  const ChartTooltip = useCallback(
    ({
      label,
      payload,
    }: {
      label: string;
      payload: {
        color: string;
        name: string;
        value: number;
        dataKey: string;
        payload: {
          elapsed_time: number;
        };
      }[];
    }) => {
      if (!payload?.[0]) return null;
      if (!label) return null;
      if (payload[0].dataKey !== "depth") return null;

      const unitMap = {
        depth: distance[preferred_depth_unit],
        water_temp: temperature[preferred_temperature_unit],
        tank_pressure: pressure[preferred_pressure_unit],
        heart_rate: "bpm",
      };

      const {
        value,
        payload: { elapsed_time },
      } = payload[0];

      const getIndexAtTime = depthData?.findIndex(
        (d) => d.elapsed_time === elapsed_time
      );

      if (typeof getIndexAtTime !== "number") return null;

      const waterTempValue = waterTempData?.[getIndexAtTime]?.water_temp;
      const tankPressureValue =
        tankPressureData?.[getIndexAtTime]?.tank_pressure;
      const heartRateValue = heartRateData?.[getIndexAtTime]?.heart_rate;
      const showHours = elapsed_time > 60 * 60;
      const convertedTime = secondsToTime(elapsed_time, showHours);

      return (
        <div className={classes.tooltip}>
          <Text className={classes.tooltipText} size="sm" component="span">
            Elapsed time: {convertedTime}
          </Text>
          <TooltipRow
            value={value}
            color={colors.depth!}
            label={labelMap.depth}
            unit={unitMap.depth}
          />
          {typeof waterTempValue === "number" && (
            <TooltipRow
              value={waterTempValue}
              color={colors.water_temp!}
              label={labelMap.water_temp}
              unit={unitMap.water_temp}
            />
          )}
          {typeof heartRateValue === "number" && (
            <TooltipRow
              value={heartRateValue}
              color={colors.heart_rate!}
              label={labelMap.heart_rate}
              unit={unitMap.heart_rate}
            />
          )}
          {typeof tankPressureValue === "number" && (
            <TooltipRow
              value={tankPressureValue}
              color={colors.tank_pressure!}
              label={labelMap.tank_pressure}
              unit={unitMap.tank_pressure}
            />
          )}
        </div>
      );
    },
    [
      preferred_depth_unit,
      preferred_temperature_unit,
      preferred_pressure_unit,
      depthData,
      waterTempData,
      tankPressureData,
      heartRateData,
    ]
  );

  return (
    <div className={classes.graphsContainer}>
      {depthData?.length ? (
        <ResponsiveContainer>
          <AreaChart
            {...commonGraphProps}
            gridAxis="x"
            xAxisProps={{
              tickLine: true,
              interval: "preserveStart",
              tickFormatter(value) {
                const showHours = value > 60 * 60;
                return secondsToTime(value, showHours);
              },
              minTickGap: 25,
              className: classes.visible,
            }}
            yAxisProps={{
              domain: depthDomain,
              ticks: depthDomain,
              ...commonYAxisProps,
              reversed: true,
              minTickGap: 10,
              tickSize: 0,
              tickFormatter(value) {
                return (Math.round(value / 2) * 2).toLocaleString();
              },
              className: classes.visible,
              unit: distance[preferred_depth_unit],
            }}
            data={depthData}
            series={[{ name: "depth", color: colors.depth! }]}
            tooltipProps={{
              // @ts-expect-error - ???
              content: ChartTooltip,
            }}
            areaProps={areaProps}
          />
        </ResponsiveContainer>
      ) : null}
      {waterTempData?.length ? (
        <div className={classes.graphContainer}>
          <ResponsiveContainer height="90%">
            <AreaChart
              {...commonGraphProps}
              yAxisProps={{
                domain: waterTempDomain,
                ticks: waterTempDomain,
                ...commonYAxisProps,
              }}
              data={waterTempData}
              series={[{ name: "water_temp", color: colors.water_temp! }]}
              curveType="linear"
              tooltipProps={{
                // @ts-expect-error - ???
                content: ChartTooltip,
              }}
              areaProps={areaProps}
            />
          </ResponsiveContainer>
        </div>
      ) : null}
      {tankPressureData?.length ? (
        <div className={classes.graphContainer}>
          <ResponsiveContainer>
            <AreaChart
              {...commonGraphProps}
              yAxisProps={{
                domain: tankPressureDomain,
                ...commonYAxisProps,
              }}
              data={tankPressureData}
              series={[{ name: "tank_pressure", color: colors.tank_pressure! }]}
              curveType="linear"
              tooltipProps={{
                // @ts-expect-error - ???
                content: ChartTooltip,
              }}
              areaProps={areaProps}
            />
          </ResponsiveContainer>
        </div>
      ) : null}
      {heartRateData?.length ? (
        <div className={classes.graphContainer}>
          <ResponsiveContainer
            height="50%"
            style={{
              transform: "translateY(50%)",
            }}
          >
            <AreaChart
              {...commonGraphProps}
              yAxisProps={{
                domain: heartRateDomain,
                ...commonYAxisProps,
              }}
              data={heartRateData}
              series={[{ name: "heart_rate", color: colors.heart_rate }]}
              tooltipProps={{
                // @ts-expect-error - ???
                content: ChartTooltip,
              }}
              areaProps={areaProps}
            />
          </ResponsiveContainer>
        </div>
      ) : null}
    </div>
  );
}
