import { TextInput } from "@mantine/core";
import * as classes from "./NumberInput.css";

export function NumberInput(
  props: React.ComponentProps<typeof TextInput> & {
    isInteger?: boolean;
    setFieldValue: (field: string, value: string | null) => void;
    min?: number;
    max?: number;
  }
) {
  const { isInteger, setFieldValue, ...rest } = props;

  return (
    <TextInput
      {...rest}
      classNames={{
        root: classes.root,
        input: classes.input,
        label: classes.label,
        section: classes.section,
      }}
      type="text"
      inputMode="numeric"
      value={props.value ?? ""}
      onChange={(e) => {
        const v = e.target.value;

        if (typeof props.min === "number" && +v < props.min) {
          return;
        }

        if (typeof props.max === "number" && +v > props.max) {
          return;
        }

        if (rest.name && v === "") {
          setFieldValue?.(rest.name, null);
        } else if (isInteger && rest.name) {
          const int = v.replace(/[^0-9]/g, "");
          setFieldValue?.(rest.name, int);
        } else if (rest.name) {
          const float = v.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
          setFieldValue?.(rest.name, float);
        } else {
          console.error("NumberInput: no name prop provided");
        }
      }}
    />
  );
}
