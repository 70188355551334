import {
  DISTANCE_UNITS,
  TEMPERATURE_UNITS,
  WEIGHT_UNITS,
  PRESSURE_UNITS,
  VOLUME_UNITS,
} from "~/constants/enums";

export const UNIT_DEFAULTS = {
  DEPTH: DISTANCE_UNITS.METRES,
  TEMPERATURE: TEMPERATURE_UNITS.CELSIUS,
  WEIGHT: WEIGHT_UNITS.KILOGRAMS,
  PRESSURE: PRESSURE_UNITS.BAR,
  VOLUME: VOLUME_UNITS.LITRES,
} as const;
