import { Select as MantineSelect } from "@mantine/core";
import * as classes from "./Select.css";

export function Select(
  props: React.ComponentProps<typeof MantineSelect> & {
    loading?: boolean;
  }
) {
  const { loading, ...rest } = props;
  return (
    <MantineSelect
      classNames={{
        wrapper: loading ? classes.loading : "",
        input: classes.input,
        label: classes.label,
        dropdown: classes.dropdown,
        option: classes.option,
      }}
      allowDeselect={false}
      {...rest}
      placeholder={loading ? "Loading..." : rest.placeholder}
    />
  );
}
