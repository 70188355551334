import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { SegmentedControl } from "~/components/_common/form/SegmentControl/SegmentControl";
import { VisuallyHidden } from "@mantine/core";
import { COOKIE_KEYS } from "~/config/cookie-keys";

export function CookieTabs({
  options,
  cookieKey,
  value,
  setValue,
}: {
  options: (
    | {
        label: string;
        value: string;
      }
    | {
        value: string;
        icon: React.ReactNode;
        hiddenLabel: string;
      }
  )[];
  value: string;
  setValue: (value: string) => void;
  cookieKey: COOKIE_KEYS;
}) {
  useEffect(() => {
    if (value) {
      Cookies.set(cookieKey, value);
    }
  }, [cookieKey, value]);

  useEffect(() => {
    if (!value) {
      const cookieValue = Cookies.get(cookieKey);

      if (cookieValue) {
        setValue(cookieValue);
      } else {
        setValue(options[0].value);
      }
    }
  }, [cookieKey, options, setValue, value]);

  const segmentOptions = options.map((option) => {
    if ("label" in option) {
      return option;
    }

    return {
      value: option.value,
      label: (
        <>
          <span style={{ display: "flex" }}>{option.icon}</span>
          <VisuallyHidden>{option.hiddenLabel}</VisuallyHidden>
        </>
      ),
    };
  });

  return (
    <SegmentedControl
      onWhite
      data={segmentOptions}
      value={value}
      onChange={setValue}
    />
  );
}
