import { Slider as MantineSlider, Text, type SliderProps } from "@mantine/core";
import * as classes from "./Slider.css";

export function Slider(
  props: Omit<SliderProps, "marks"> & {
    marks: string[];
  }
) {
  return (
    <div className={classes.container}>
      {props.label && (
        <Text className={classes.label} component="label">
          <>{props.label}</>
        </Text>
      )}
      <MantineSlider
        {...props}
        color="blue"
        showLabelOnHover
        marks={props.marks.map((mark, i) => ({ value: i, label: mark }))}
        label={(i) => {
          if (!props.marks[i]) return null;

          return (
            <span
              className={
                i === props.marks.length - 1 ? classes.isLastOption : ""
              }
            >
              {props.marks[i]}
            </span>
          );
        }}
        step={1}
        min={0}
        max={props.marks.length - 1}
        classNames={{
          root: classes.root,
          track: classes.track,
          bar: classes.bar,
          mark: classes.mark,
          markLabel: classes.markLabel,
          thumb: classes.thumb,
          label: classes.label,
        }}
      />
    </div>
  );
}
