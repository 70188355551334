import { TimeInput as MantineTimeInput } from "@mantine/dates";
import * as classes from "./TimeInput.css";

export function TimeInput(
  props: React.ComponentProps<typeof MantineTimeInput>
) {
  return (
    <MantineTimeInput
      {...props}
      value={props.value || ""}
      classNames={{
        input: classes.input,
        label: classes.label,
      }}
    />
  );
}
