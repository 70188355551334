import { Database } from "~/types/supabase";

export type GenderOption = Database["public"]["Enums"]["genders"];
export const GENDER_OPTIONS: {
  [key: string]: GenderOption;
} = {
  MALE: "male",
  FEMALE: "female",
  OTHER: "other",
  NON_BINARY: "non-binary",
  PREFER_NOT_TO_SAY: "not-said",
};

export type DistanceUnit = Database["public"]["Enums"]["distance_units"];
export const DISTANCE_UNITS: {
  [key: string]: DistanceUnit;
} = {
  METRES: "metres",
  FEET: "feet",
};

export type TemperatureUnit = Database["public"]["Enums"]["temperature_units"];
export const TEMPERATURE_UNITS: {
  [key: string]: TemperatureUnit;
} = {
  CELSIUS: "celsius",
  FAHRENHEIT: "fahrenheit",
};

export type WeightUnit = Database["public"]["Enums"]["weight_units"];
export const WEIGHT_UNITS: {
  [key: string]: WeightUnit;
} = {
  KILOGRAMS: "kg",
  POUNDS: "pounds",
};

export type PressureUnit = Database["public"]["Enums"]["pressure_units"];
export const PRESSURE_UNITS: {
  [key: string]: PressureUnit;
} = {
  PSI: "psi",
  BAR: "bar",
};

export type VolumeUnit = Database["public"]["Enums"]["volume_units"];
export const VOLUME_UNITS: {
  [key: string]: VolumeUnit;
} = {
  LITRES: "litres",
  CUBIC_FEET: "cubic-feet",
};

export type TankType = Database["public"]["Enums"]["tank_types"];
export type EntryType = Database["public"]["Enums"]["entry_types"];
export type DiveMode = Database["public"]["Enums"]["dive_modes"];
export type WaterType = Database["public"]["Enums"]["water_types"];
export type DiveType = Database["public"]["Enums"]["dive_types"];
