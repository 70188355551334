import { LoggedDive } from "~/types/shared";

export function getDiveSiteName({
  dive_sites,
  temp_dive_site_name,
}: Partial<LoggedDive>) {
  return dive_sites?.dive_site_name || temp_dive_site_name;
}

export function getDiveLogLocation({
  dive_sites,
  temp_dive_site_location,
}: Partial<LoggedDive>) {
  return dive_sites?.location || temp_dive_site_location;
}
