import type {
  DiveMode,
  DiveType,
  EntryType,
  TankType,
  WaterType,
} from "~/constants/enums";
import { DB } from "~/types/shared";

export type DiveLogFormInputs = {
  trip_id: string | null;
  dive_site_id: string | null;
  dive_site_name?: string | null;
  company_id?: string | null;
  company_name?: string | null;
  conservation_organisation_id?: string | null;
  conservation_organisation_name?: string | null;
  conservation_activities?: string[];
  company_search_name?: string | null;
  entry_date: string | null;
  entry_time: string | null;
  dive_duration: number | null;
  max_depth: number | null;
  min_water_temp: number | null;
  max_water_temp: number | null;
  air_temp: number | null;
  pressure_start: number | null;
  pressure_end: number | null;
  tank_oxygen: number | null;
  tank_po2: number | null;
  tank_size: number | null;
  current: number | null;
  visibility: number | null;
  surface_conditions: number | null;
  preferred_volume_unit: DB["user_profiles"]["Row"]["preferred_volume_unit"];
  preferred_pressure_unit: DB["user_profiles"]["Row"]["preferred_pressure_unit"];
  preferred_temperature_unit: DB["user_profiles"]["Row"]["preferred_temperature_unit"];
  preferred_depth_unit: DB["user_profiles"]["Row"]["preferred_depth_unit"];
  dive_mode: DiveMode | null;
  tank_type: TankType | null;
  entry_type: EntryType | null;
  water_type: WaterType | null;
  dive_type: DiveType | null;
};

export type DiveLogErrors = {
  [K in keyof DiveLogFormInputs]?: string | null;
} & {
  misc?: string;
};

/** Seconds */
export const SAMPLE_RATE = 15;

export type DiveSample = {
  depth?: number;
  timestamp?: Date | string;
  time?: string;
  /** Seconds */
  elapsed_time?: number;
  water_temp?: number;
  heart_rate?: number;
  tank_pressure?: number;
  po2?: number;
  cns?: number;
  no_deco_time?: number;
};

export const FILE_TYPES = {
  FIT: "fit",
  UDDF: "uddf",
  XML: "xml",
  TEXT_XML: "text/xml",
} as const;

export type FILE_TYPES = (typeof FILE_TYPES)[keyof typeof FILE_TYPES];

export const VISIBILITY_LABELS = ["Not specified", "Poor", "Good", "Amazing"];
export const CURRENT_LABELS = ["Not specified", "Calm", "Moderate", "Strong"];
export const SURFACE_CONDITION_LABELS = [
  "Not specified",
  "Calm",
  "Choppy",
  "Rough",
];

export const CORAL_ACTIVITES = [
  {
    label: "Coral outplanting",
    value: "coral-outplanting",
  },
  {
    label: "Coral monitoring",
    value: "coral-monitoring",
  },
  {
    label: "Coral research",
    value: "coral-research",
  },
];

export const MARINE_ACTIVITES = [
  {
    label: "Shark conservation",
    value: "shark-conservation",
  },
  {
    label: "Sea turtle conservation",
    value: "sea-turtle-conservation",
  },
  {
    label: "Megafauna research",
    value: "megagauna-research",
  },
  {
    label: "Sample collection",
    value: "sample-collection",
  },
];

export const OTHER_ACTIVITES = [
  {
    label: "Debris / trash removal",
    value: "debris-removal",
  },
  {
    label: "Net removal",
    value: "net-removal",
  },
];

export const CONSERVATION_ACTIVITIES = [
  ...CORAL_ACTIVITES,
  ...MARINE_ACTIVITES,
  ...OTHER_ACTIVITES,
];
