import { useEffect } from "react";
import { useFetcher } from "@remix-run/react";
import {
  IconBeach,
  IconScubaDiving,
  IconSpeedboat,
  IconWorld,
  IconFish,
} from "@tabler/icons-react";
import { AutocompleteProps, Group, Text, Loader } from "@mantine/core";
import { Autocomplete } from "~/components/_common/form/Autocomplete/Autocomplete";
import { vars } from "~/config/theme";
import { loader } from "~/routes/_api.get-dive-companies";
import { GET_DIVE_COMPANIES } from "~/config/api";
import { CompanyType } from "~/types/shared";
import * as classes from "./DiveCompanySearch.css";

const iconMap: {
  [key in CompanyType]: React.ReactNode;
} = {
  operator: <IconWorld />,
  resort: <IconBeach />,
  centre: <IconScubaDiving />,
  liveaboard: <IconSpeedboat />,
  conservation: <IconFish />,
};

export function DiveCompanySearch({
  diveCompanyNameInputProps,
  setDiveCompanyFields,
  onChange,
  error,
  companyType,
  notCompanyType,
  hideLabel,
  onAddNewCompany,
  placeholder = "Search dive companies...",
  fetcherKey = "get-dive-company",
}: {
  diveCompanyNameInputProps: React.ComponentProps<typeof Autocomplete>;
  setDiveCompanyFields: (
    diveCompanyFields: {
      id: string;
      company_name: string;
    } | null
  ) => void;
  onChange: (value: string) => void;
  error?: string | null;
  companyType?: CompanyType;
  notCompanyType?: CompanyType;
  hideLabel?: boolean;
  onAddNewCompany?: () => void;
  placeholder?: string;
  fetcherKey?: string;
}) {
  const value = diveCompanyNameInputProps.value;
  const { load, data, state } = useFetcher<typeof loader>({
    key: `${fetcherKey}-${value}`,
  });

  const { results, error: searchError } = data || {};

  const selectedCompany = results?.find(
    ({ company_name }) => value === company_name
  );

  const noResultsMessage =
    state === "idle" && results?.length === 0
      ? `No results containing "${value}"`
      : null;

  const renderAutocompleteOption: AutocompleteProps["renderOption"] = ({
    option,
  }) => {
    if (noResultsMessage) {
      return (
        <button
          type="button"
          className={classes.addCompanyButton}
          onClick={() => onAddNewCompany?.()}
        >
          <Text size="xs" mb="xs">
            {noResultsMessage}
          </Text>
          <Text size="sm">Click here to add it...</Text>
        </button>
      );
    }

    const item = results?.find(({ id }) => id === option.value);

    if (!item) return null;

    return (
      <Group gap="sm">
        <div>
          <div className={classes.iconTextItem}>
            {item?.company_type &&
              iconMap[item.company_type as keyof typeof iconMap]}
            <div>
              <Text size="sm">{item.company_name}</Text>
              <Text size="xs" opacity={0.7} lineClamp={1}>
                {item.location ?? item.countries?.country}
              </Text>
            </div>
          </div>
        </div>
      </Group>
    );
  };

  const ids = results?.map(({ company_name, id }) => ({
    value: id ?? "",
    label: company_name ?? "",
  }));

  useEffect(() => {
    if (selectedCompany) {
      setDiveCompanyFields(selectedCompany);
    } else {
      setDiveCompanyFields({
        id: "",
        company_name: value ?? "",
      });
    }
  }, [setDiveCompanyFields, selectedCompany, value]);

  useEffect(() => {
    if (value && value?.length) {
      load(
        `${GET_DIVE_COMPANIES}?search=${value}&companyType=${
          companyType ?? ""
        }&notCompanyType=${notCompanyType ?? ""}`
      );
    } else if (value === "") {
      setDiveCompanyFields(null);
    }
  }, [companyType, notCompanyType, setDiveCompanyFields, load, value]);

  const valueSplit = value?.split("");
  const [firstLetter, ...rest] = valueSplit ?? [];
  const firstLetterUppercase = firstLetter?.toUpperCase();
  const valueUppercase = [firstLetterUppercase, ...rest].join("");

  return (
    <div className={classes.container}>
      <Autocomplete
        {...diveCompanyNameInputProps}
        data={
          ids?.length
            ? ids
            : valueUppercase
            ? // This is a hack to show an error message in the dropdown
              [{ value: "error", label: valueUppercase! }]
            : []
        }
        renderOption={renderAutocompleteOption}
        maxDropdownHeight={300}
        label={
          !hideLabel
            ? companyType
              ? `${companyType} name`
              : "Dive company (operator / centre / resort / liveaboard) name"
            : ""
        }
        placeholder={placeholder}
        value={value ?? ""}
        onChange={onChange}
        error={Boolean(error || searchError || noResultsMessage)}
        {...(state === "idle" ? {} : { dropdownOpened: false })}
      />
      {state !== "idle" ? (
        <Loader
          className={classes.loader}
          size="xs"
          color={vars.colors.dark[4]}
        />
      ) : null}
    </div>
  );
}
