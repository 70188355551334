import { Link, useOutletContext } from "@remix-run/react";
import { PROFILE } from "~/config/routes";
import { theme } from "~/config/theme";
import { hashStringToIndex } from "~/utils/hashStringToIndex";
import { ButtonIcon } from "~/components/_common/ButtonIcon/ButtonIcon";
import type { OutletContext } from "~/types/shared";
import * as classes from "./UserAvatar.css";

export function UserAvatar({
  size = 32,
  userId,
  username,
  imageUrl,
  label,
  onClick,
  disabled,
}: {
  userId?: string;
  username?: string;
  imageUrl?: string | null;
  label?: string;
  size?: number;
  disabled?: boolean;
  onClick?: () => void;
}) {
  const { prefetchType = "none" } = useOutletContext<OutletContext>() || {};
  const styles = {
    width: size,
    height: size,
  };

  const LinkOrButton = ({ children }: { children: React.ReactNode }) =>
    onClick ? (
      <ButtonIcon
        label={label}
        onClick={onClick}
        disabled={disabled}
        className={classes.common}
        style={styles}
      >
        {children}
      </ButtonIcon>
    ) : userId ? (
      <Link
        aria-label={label}
        to={`${PROFILE}/${userId}`}
        className={classes.common}
        style={styles}
        prefetch={prefetchType}
      >
        {children}
      </Link>
    ) : (
      <div style={styles} className={classes.common}>
        {children}
      </div>
    );

  if (imageUrl) {
    return (
      <LinkOrButton>
        <img
          className={classes.profileImage}
          src={imageUrl}
          alt={`Avatar for ${username}`}
        />
      </LinkOrButton>
    );
  }
  const initials = username?.slice(0, 2).toUpperCase();
  const colorIndex = hashStringToIndex(initials || "", 7);
  const backgroundColor = theme.colors?.dark?.[colorIndex] || "";
  return (
    <LinkOrButton>
      <div
        className={classes.profileImage}
        style={{
          ...styles,
          backgroundColor,
          fontSize: `${size / 2}px`,
        }}
      >
        {username?.slice(0, 2).toUpperCase()}
      </div>
    </LinkOrButton>
  );
}
