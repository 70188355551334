import { useOutletContext } from "@remix-run/react";
import {
  temperature,
  distance,
  pressure,
  volume,
} from "~/config/abbreviations";
import { OutletContext } from "~/types/shared";
import {
  celsiusToFahrenheit,
  metresToFeet,
  minutesToTimeLong,
  litresToCubicFeet,
  barToPsi,
} from "../conversions";
import {
  VISIBILITY_LABELS,
  CURRENT_LABELS,
  SURFACE_CONDITION_LABELS,
} from "~/config/dive-log";

type Types =
  | "temperature"
  | "depth"
  | "pressure"
  | "volume"
  | "time"
  | "minutes"
  | "visibility"
  | "current"
  | "surface-conditions";

export function useConvertedValue() {
  const { userProfile } = useOutletContext<OutletContext>();

  const {
    preferred_temperature_unit,
    preferred_depth_unit,
    preferred_pressure_unit,
    preferred_volume_unit,
  } = userProfile;

  return (value: string | number | undefined | null, type?: Types) => {
    if (
      value === null ||
      value === undefined ||
      value === Infinity ||
      value === -Infinity ||
      value === ""
    ) {
      return "-";
    }

    if (type === "visibility") {
      return value ? VISIBILITY_LABELS[value as number] ?? "-" : "-";
    }

    if (type === "current") {
      return value ? CURRENT_LABELS[value as number] ?? "-" : "-";
    }

    if (type === "surface-conditions") {
      return value ? SURFACE_CONDITION_LABELS[value as number] ?? "-" : "-";
    }

    if (type === "temperature") {
      const convertedValue =
        preferred_temperature_unit === "celsius"
          ? value
          : celsiusToFahrenheit(value);
      return `${Math.round(Number(convertedValue))}${
        temperature[preferred_temperature_unit]
      }`;
    }

    if (type === "depth") {
      const convertedValue =
        preferred_depth_unit === "metres" ? value : metresToFeet(value);
      return `${Math.round(Number(convertedValue))}${
        distance[preferred_depth_unit]
      }`;
    }

    if (type === "pressure") {
      const convertedValue =
        preferred_pressure_unit === "bar" ? value : barToPsi(value);
      return `${Math.round(Number(convertedValue)).toLocaleString()} ${
        pressure[preferred_pressure_unit]
      }`;
    }

    if (type === "volume") {
      const convertedValue =
        preferred_volume_unit === "litres" ? value : litresToCubicFeet(value);
      return `${Number(convertedValue).toLocaleString()} ${
        volume[preferred_volume_unit]
      }`;
    }

    if (type === "time") {
      return `${value} min`;
    }

    if (type === "minutes") {
      return minutesToTimeLong(value);
    }

    return value;
  };
}
