import { MultiSelect as MantineMultiSelect } from "@mantine/core";
import * as classes from "./MultiSelect.css";

export function MultiSelect(
  props: React.ComponentProps<typeof MantineMultiSelect>
) {
  return (
    <MantineMultiSelect
      {...props}
      classNames={{
        input: props?.value?.length ? classes.input : classes.inputEmpty,
        dropdown: classes.dropdown,
        pillsList: classes.pillsList,
        pill: classes.pill,
        inputField: props?.value?.length
          ? classes.inputFieldHidden
          : classes.inputField,
      }}
    />
  );
}
